import React, { useCallback, useMemo, useState } from 'react';
import dateFnsFormat from 'date-fns/format';
import isValid from 'date-fns/isValid';
import addDays from 'date-fns/addDays';
import dateFnsParse from 'date-fns/parse';
import isDate from 'date-fns/isDate';

export function useCalendarb(initData = [ new Date() ], min, disabled) {
	const [ error, setError ] = useState(false);
	const modifires = {
		sunday: { daysOfWeek: [ 0, 6 ] }
		//hilight: toDateArray(initData)
	};
	
	const formatDate = useCallback(
		(date) => {
			try {
				if(date == null){
					return '';
				}
				else{
					return dateFnsFormat(date, 'dd.MM.yyyy');
				}
				
			} catch (e) {
				console.log(isValid(initData),initData,'ошибка в форматировании даты');
				setError(true);
				return '';
			}
		},
		[JSON.stringify(initData)],
	)
	

	const getAllDates = useCallback(
		() => {
			try {
				const dates = initData;
				if (isDate(dates[0])) {
					return dates.map((date) => dateFnsFormat(date, 'dd.MM.yyyy'));
				} else if (typeof dates[0] == 'string') {
					return dates;
				} else {
					return dates.length && dates.map(({ date }) => date);
				}
			} catch (e) {
				console.log('ошибка в gatAllDates');
				setError(true);
			}
		},
		[ JSON.stringify(initData) ]
	);
	initData = getAllDates();

	const memoCalendarb = useMemo(() => {
		let calendarb = [];
		try{
		
		if (initData.length > 0 && typeof initData != 'undefined') {
			
			for (let i = 0; i < 365; i++) {
				let data = dateFnsFormat(addDays(new Date(), i), 'dd.MM.yyyy');
				data.toString();
				calendarb.push(data);
			}
		}
		return calendarb;
		}
		catch (e) {
			console.log('ошибка в memoCalendarb');
			setError(true);
			return calendarb;
		}
	}, [JSON.stringify(initData) ]);

	const getMaxDate = useCallback(() => (initData.length > 1 ? initData[initData.length - 1] : null), [ JSON.stringify(initData)  ]);
	const getMinDate = useCallback(
		() => {
			
			return initData.length ? dateFnsParse(initData[0], 'dd.MM.yyyy', new Date()) : new Date();
		},
		[ JSON.stringify(initData) ]
	);
	const getDisableDate = useCallback(
		() => {
			try{
				console.log(min);
				let disableDate = [];
				
				const dates = initData;
				
				if (dates.length != 0) {
					disableDate = memoCalendarb.filter((item) => {
				
						if (item != dates.find((day) => day == item)) {
							return item;
						}
					});
				
					disableDate = disableDate.map((day) => dateFnsParse(day, 'dd.MM.yyyy', new Date()));
					disableDate.push({ before: min ? min : getMinDate()  });
					disableDate.push({
						after: getMaxDate() ? dateFnsParse(getMaxDate(), 'yyyy-MM-dd', new Date()) : addDays(new Date(), 90)
					});
				} else {
					disableDate.push({ before: getMinDate || new Date() });
				}
				
				return disableDate;
			}
			catch{
				console.log('ошибка в getDisableDate');
			setError(true);
			}
			
		},
		[ JSON.stringify(initData),min ]
	);

	return { getDisableDate, getMaxDate, getMinDate, getAllDates, formatDate, modifires, error };
}
