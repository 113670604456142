import React, { useContext, useState, useEffect, useCallback } from 'react';

import { FormContext } from './context';

import { AutoCompleate } from '../../components/autoCompleate';
import { TextInput } from '../../components/textInput';
import { useRequest } from '../../hooks/useRequest';
import { http, filtredPoints, getDefaultValue, generateBodyRequestForm } from '../../api';
import dateFnsFormat from 'date-fns/format';
import { DatePicker } from '../../components/datepicker';
import { Counter } from '../../components/Counter';
let renderFields = 0;
export const Fields = () => {
	//props
	const {
		fields = [],
		url: { url_direction = '', url_date = '', url_redirect = '' },
		request_modifire = '',
		sessid = '',
		css
	} = useContext(FormContext);
	//requset hook
	const { request, loading, error } = useRequest();

	//body

	//direction and points
	const [ points, setPoints ] = useState({
		ID: '',
		NAME: ''
	});
	const [ directions, setDirections ] = useState({});

	const getDataPoints = async () => {
		const result = await request(http(url_direction), 'Загрузка направление', 'Ошибка загрузки');
		setPoints(result.points);
		setDirections(result.direction);
	};

	useEffect(() => {
		getDataPoints();
	}, []);

	//dates
	const [ dates, setDates ] = useState([]);
	const [ dates_back, setDatesBack ] = useState([]);

	const getDates = (data1, data2, callback) => async () => {
		try {
			const result = await request(
				http(`${url_date}/?sessid=${sessid}&city_from=${data1}&city_to=${data2}`),
				'Загрузка дат',
				'Ошибка загрузки дат'
			);
			callback(result.dates);
			//setDatesBack(result.dates);
		} catch (e) {
			// setDates([]);
			// setDatesBack([]);
		}
	};

	//set Body
	const [ body, setBody ] = useState({
		sessid: '',
		city_from_id: { ID: '', NAME: '', KEY: '' },
		city_to_id: { ID: '', NAME: '', KEY: '' },
		date: null,
		date_back: null,
		pessanger: getDefaultValue(fields, 'COUNT', 1)
	});
	const setData = (key) => (data) => {
		setBody({ ...body, [key]: data });
		if (key == 'city_to_id' && Object.values(body.city_from_id).length && Object.values(data).length) {
			getDates(body.city_from_id.KEY, data.KEY, setDates)();
			//getDates(body.city_from_id.KEY, data.KEY, setDatesBack)();
			getDates(data.KEY, body.city_from_id.KEY, setDatesBack)();
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const url = generateBodyRequestForm(body, request_modifire, url_redirect, sessid);
		window.location.href = url;
	};

	return (
		<form autoComplete="nope" id="transferForm">
			{fields.map((field, index) => {
				switch (field.type) {
					case 'AC':
						if (field.name == 'city_from_id') {
							return (
								<AutoCompleate
									disabled={!points ? true : false}
									key={`field_${index}_from`}
									field={field}
									value={body[field.name]}
									setData={setData(field.name)}
									css={css}
									options={points}
								/>
							);
						}
						if (field.name == 'city_to_id') {
							return (
								<AutoCompleate
									disabled={!body.city_from_id.ID.length ? true : false}
									key={`field_${index}_to`}
									field={field}
									value={body[field.name]}
									setData={setData(field.name)}
									css={css}
									options={
										body.city_from_id ? (
											filtredPoints(directions[body['city_from_id'].ID], points)
										) : null
									}
								/>
							);
						}

					case 'DP':
						if (field.name == 'date') {
							return (
								<DatePicker
									disabled={dates.length == 0 ? true : false}
									key={`date_${index}_from`}
									field={field}
									value={body[field.name]}
									setData={setData(field.name)}
									css={css}
									dates={dates}
								/>
							);
						}
						if (field.name == 'date_back') {
							return (
								<DatePicker
									disabled={dates_back.length == 0 ? true : false}
									minDate={body.date}
									key={`field_${index}`}
									field={field}
									value={body[field.name]}
									setData={setData(field.name)}
									closeBtn={true}
									css={css}
									dates={dates_back}
								/>
							);
						}
						break;

					case 'COUNT':
						return (
							<Counter
								disabled={!body.city_from_id.ID.length && !body.city_to_id.ID.length ? true : false}
								key={`counter_${index}_fromm`}
								field={field}
								value={body[field.name]}
								setData={setData(field.name)}
								css={css}
							/>
						);
					case 'SUBMIT':
						const { css_icon = '', css_group = '', css_submit = '' } = css;
						const { icon = '', defaultValue = '', typeControl = '' } = field;
						return (
							<div className={css_group}>
								<button
									key={'submit'}
									disabled={
										!body.city_from_id.KEY.length &&
										!body.city_to_id.KEY.length &&
										body.date != null ? (
											true
										) : (
											false
										)
									}
									type={typeControl}
									className={css_submit}
									onClick={handleSubmit}
								>
									{icon.length ? <i className={[ css_icon, icon ].join(' ')} /> : null}
									{defaultValue}
								</button>
							</div>
						);
				}
			})}
		</form>
	);
};
