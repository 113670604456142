import React from 'react';
import { Fields } from './fields';
import { FormContext } from './context';


export const Form = (props) => {


	return (
		<>
			<FormContext.Provider value={props}>
				<Fields />
			</FormContext.Provider>
		</>
	);
};
